<template>
  <div class="home">
    Home
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">

</style>
